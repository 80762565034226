import {Container, ProviderLabel} from "../models";
import {action, makeObservable, observable, ObservableMap, runInAction} from "mobx";
import {getContainers} from "../api";
import hostsStore from "./HostsStore";

export class ContainersStore {
    containersRegistry: ObservableMap<string, ObservableMap<string, Container>> = observable.map()
    loading: ObservableMap<string, boolean> = observable.map()
    error: ObservableMap<string, string> = observable.map()
    intervals = observable.map()
    activeContainer: Container | null = null

    constructor() {
        makeObservable(this, {
            loading: observable,
            error: observable,
            activeContainer: observable,
            getLoading: observable,
            getError: observable,
            getContainers: observable,
            setLoading: action,
            setError: action,
            noError: action,
            setActiveContainer: action,
        })
    }

    getLoading(host: string) {
        return this.loading.get(host) !== undefined && this.loading.get(host)
    }

    getError(host: string) {
        const err = this.error.get(host)
        if (err === undefined) {
            return ""
        } else {
            return err
        }
    }

    getContainers(host: string, status: string = "all", providers: string[] = [], nameFilter: string = "") {
        const ret: Container[] = []
        if (this.containersRegistry.get(host) === undefined) return ret;
        const values = this.containersRegistry.get(host)?.values();
        // @ts-ignore
        for (let val of values) {
            let include = false;
            switch (status) {
                case "all":
                    include = true
                    break
                case "running":
                    if (val.status === "running") {
                        include = true
                    }
                    break
                case "exited":
                    if (val.status === "exited") {
                        include = true
                    }
                    break
            }
            if (providers.length > 0) {
                if (!this.hasProviders(val, providers)) {
                    include = false
                }
            }
            if (nameFilter.length > 0 && !val.name.includes(nameFilter)) {
                include = false
            }
            if (include && !ret.includes(val)) {
                ret.push(val)
            }
        }
        ret.sort((a, b) => {
            if (a.status < b.status) {
                return 1
            } else if (a.status > b.status) {
                return -1
            } else {
                return a.name.localeCompare(b.name)
            }
        })
        return ret
    }

    setLoading(host: string, loading: boolean) {
        this.loading.set(host, loading)
    }

    setError(host: string, err: string) {
        this.error.set(host, err)
    }

    noError(host: string) {
        this.error.set(host, "")
    }

    loadContainers(host: string) {
        if (this.loading.get(host) !== undefined && this.loading.get(host)) return
        if (!hostsStore.hasHost(host)) {
            runInAction(() => {
                if (this.intervals.has(host)) {
                    clearInterval(this.intervals.get(host))
                    this.intervals.delete(host)
                    this.containersRegistry.delete(host)
                }
            })
            return;
        }
        this.setLoading(host, true)
        getContainers(host)
            .then(resp => {
                if (resp.status !== 200) {
                    throw resp
                } else {
                    return resp.json()
                }
            })
            .then(data => {
                runInAction(() => {
                    if (this.containersRegistry.get(host) !== undefined) {
                        this.containersRegistry.get(host)?.clear()
                    } else {
                        this.containersRegistry.set(host, observable.map())
                    }
                    data.forEach((container: Container) => {
                        container.host = host
                        this.containersRegistry.get(host)?.set(container.id, container)
                    })
                })
                this.noError(host)
            })
            .catch(e => {
                if (e instanceof Response) {
                    this.setError(host, e.statusText)
                } else {
                    this.setError(host, "server is down")
                }
            })
            .finally(() => {
                this.setLoading(host, false)
                if (this.intervals.get(host) === undefined) {
                    runInAction(() => {
                        this.intervals.set(host, setInterval(() => this.loadContainers(host), 5000))
                    })
                }
            })
    }

    setActiveContainer(container: Container | null) {
        //console.log("set active container: ", container)
        this.activeContainer = container
    }

    isActive(item: Container) {
        return this.activeContainer != null
            && this.activeContainer.host === item.host
            && this.activeContainer.id === item.id;
    }

    getProviders() {
        let providers: string[] = [];
        this.containersRegistry.forEach((host) => {
            host.forEach((item) => {
                if (item.labels.hasOwnProperty("provider")) {
                    const labels = item.labels as ProviderLabel;
                    if (!providers.includes(labels.provider)) {
                        providers.push(labels.provider)
                    }
                }
            })
        })

        return providers;
    }

    private hasProviders(item: Container, providers: string[]) {
        if (item.labels.hasOwnProperty("provider")) {
            const labels = item.labels as ProviderLabel;
            return providers.includes(labels.provider);
        }
        return false;
    }
}

const containersStore = new ContainersStore()

export default containersStore