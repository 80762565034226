import cfg from './config.json';

export const getHosts = () => fetch(cfg.BASE_URL + "/hosts")
export const getContainers = (host: string) => fetch(cfg.BASE_URL + "/" + host + "/containers")
export const startContainer = (host: string, container: string) => fetch(cfg.BASE_URL + "/" + host + "/" + container + "/start")
export const stopContainer = (host: string, container: string) => fetch(cfg.BASE_URL + "/" + host + "/" + container + "/stop")
export const restartContainer = (host: string, container: string) => fetch(cfg.BASE_URL + "/" + host + "/" + container + "/restart")
export const runFrida = (host: string, container: string, script: string, appId: string) =>
    fetch(cfg.BASE_URL + "/" + host + "/" + container + "/run_frida", {
        method: "POST",
        body: JSON.stringify({"script": script, "app_id": appId})
    })
