import {Host} from "../models";
import {makeAutoObservable, observable, ObservableMap, runInAction} from "mobx";
import {getHosts} from "../api";

export class HostsStore {
    hostsRegistry: ObservableMap<string, Host> = observable.map()
    loading: boolean = false
    error: string = ""

    constructor() {
        makeAutoObservable(this)
    }

    get hosts() {
        const ret: Host[] = []
        const values = this.hostsRegistry.values();
        // @ts-ignore
        for (let val of values) {
            ret.push(val)
        }
        return ret
    }

    setLoading(loading: boolean) {
        this.loading = loading
    }

    setError(err: string) {
        this.error = err
    }

    noError() {
        this.error = ""
    }

    loadHosts() {
        if (this.loading) return
        this.setLoading(true)
        getHosts()
            .then(resp => {
                if (resp.status !== 200) {
                    throw resp
                } else {
                    return resp.json()
                }
            })
            .then(data => {
                runInAction(() => {
                    this.hostsRegistry.clear()
                    data.forEach((host: Host) => this.hostsRegistry.set(host.name, host))
                    this.noError()
                })
            })
            .catch(e => {
                if (e instanceof Response) {
                    this.setError(e.statusText)
                } else {
                    this.setError("server is down")
                }
            })
            .finally(() => {
                this.setLoading(false)
            })
    }

    hasHost(host: string) {
        return this.hostsRegistry.has(host);
    }
}

const hostsStore = new HostsStore()
setInterval(hostsStore.loadHosts.bind(hostsStore), 5000)

export default hostsStore