import {Component} from "react";
import {Container} from "../models";
import {restartContainer, startContainer, stopContainer} from "../api";

interface Props {
    active: boolean
    container: Container
    onConnectClick: (container: Container) => void
}

export class ContainerItem extends Component<Props, any> {
    constructor(props: any) {
        super(props);

        this.state = {actions: true}
    }
    render() {
        const labels = Object.entries(this.props.container.labels)

        let labelsView = <span/>
        if (labels.length > 0) {
            labelsView = <div>{
                labels.map((item, index) =>
                    <span key={index} className="badge small bg-primary text-muted">
                        {item.join("=")}
                    </span>
                )
            }</div>
        }

        const actionConnect = <button className="btn btn-primary btn-sm me-1 px-1 py-0"
                                      disabled={this.props.active}
                                      onClick={() => {
                                          this.props.onConnectClick(this.props.container)
                                      }}>connect</button>
        const actionStop = <button className="btn btn-danger btn-sm me-1 px-1 py-0"
                                      disabled={this.props.active}
                                      onClick={() => {
                                          this.onStopClick(this.props.container)
                                      }}>stop</button>
        const actionStart = <button className="btn btn-success btn-sm me-1 px-1 py-0"
                                   disabled={this.props.active}
                                   onClick={() => {
                                       this.onStartClick(this.props.container)
                                   }}>start</button>
        const actionRestart = <button className="btn btn-warning btn-sm me-1 px-1 py-0"
                                    disabled={this.props.active}
                                    onClick={() => {
                                        this.onRestartClick(this.props.container)
                                    }}>restart</button>

        let statusBg = "bg-success"
        let actions = <span/>
        switch (this.props.container.status) {
            case "exited":
                statusBg = "bg-danger"
                actions = <span>{actionStart}</span>
                break
            case "paused":
                statusBg = "bg-danger"
                break
            case "dead":
                statusBg = "bg-danger"
                break
            case "created":
                statusBg = "bg-warning"
                break
            case "restarting":
                statusBg = "bg-primary"
                break
            case "running":
                statusBg = "bg-success"
                actions = <span>{actionConnect}{actionRestart}{actionStop}</span>
                break
        }

        let statusBadge = <small className={"badge small mx-1 px-1 " + statusBg}>{this.props.container.status}</small>

        return <div
            className={"list-group-item d-flex justify-content-between align-items-start" + (this.props.active ? " active" : "")}>
            <div>
                <div>
                    <span className="fw-bold">{this.props.container.name}</span>
                    {statusBadge}
                </div>
                {labelsView}
            </div>
            {this.state.actions ? actions : ""}
        </div>
    }

    private onStartClick(container: Container) {
        this.setState({actions: false})
        startContainer(container.host, container.id).finally(() => this.setState({actions: true}))
    }

    private onStopClick(container: Container) {
        this.setState({actions: false})
        stopContainer(container.host, container.id).finally(() => this.setState({actions: true}))
    }

    private onRestartClick(container: Container) {
        this.setState({actions: false})
        restartContainer(container.host, container.id).finally(() => this.setState({actions: true}))
    }
}