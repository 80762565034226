import React from 'react';
import {Main} from "./components/Main";
import './App.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap-icons/font/bootstrap-icons.css'
import {ThemeSwitcherProvider} from "react-css-theme-switcher";
import {ThemeSwitcher} from "./components/ThemeSwitcher";
import {useStore} from "./store";

const themes = {
    light: "/light.css",
    dark: "/dark.css"
}

function App() {
    const {settingsStore} = useStore()
    return (
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={settingsStore.currentTheme}>
            <div className="App position-relative">
                <Main/>
                <ThemeSwitcher/>
            </div>
        </ThemeSwitcherProvider>
    );
}

export default App;
