import React, {Component, FormEvent} from "react";
import {Observer} from "mobx-react-lite";
import {RootStore} from "../stores";
import storeContext from "../store";
import cfg from '../config.json';
import containersStore from "../stores/ContainersStore";
import {runFrida} from "../api";

export class Emulator extends Component<any, any> {
    static contextType = storeContext
    private toast: any;

    constructor(props: any) {
        super(props);
        this.toast = React.createRef()
    }

    private onRunFrida(e: FormEvent) {
        e.preventDefault()
        const data = new FormData(e.target as HTMLFormElement);
        const script = data.get("script") as string;
        if (script === null || script.length === 0) {
            alert("Empty script data")
            return;
        }
        const appId = data.get("appId") as string;
        if (appId === null || appId.length === 0) {
            alert("Empty App Id")
            return;
        }

        const {containersStore} = this.context as RootStore
        const {activeContainer} = containersStore

        if (activeContainer == null) {
            return;
        }

        runFrida(activeContainer.host, activeContainer.id, script, appId).then(r => r.json()).then(data => alert(data["message"]))
    }

    render() {
        return <Observer>{() => {
            const {containersStore, settingsStore} = this.context as RootStore

            if (containersStore.activeContainer != null) {
                const item = containersStore.activeContainer
                return <div
                    className="flex-grow-1 col-9 d-flex flex-column justify-content-start align-items-center h-100">
                    <div className="modal fade modal-lg" id="fridaScript" tabIndex={-1} role="dialog"
                         aria-hidden="true">
                        <form className="modal-dialog modal-dialog-centered" onSubmit={this.onRunFrida.bind(this)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <label className="form-label">App Id:
                                        <input type="text" className="form-control form-control-sm"
                                               name="appId" spellCheck={"false"}/></label>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <strong>Script:</strong>
                                    <textarea className="form-control" rows={15} spellCheck={"false"}
                                              name="script"></textarea>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Run
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="flex-grow-1 h-100" style={{width: "600px"}}>
                        <iframe className="emulator-frame"
                                src={"/ws.html?host=" + item.host + "&container=" + item.id + "&base=" + cfg.BASE_WS_URL + "&theme=" + settingsStore.currentTheme}
                                title={item.host + item.name}/>
                    </div>
                    <div className="mt-1">
                        <button type="button" data-bs-toggle="modal" data-bs-target="#fridaScript"
                                className="btn btn-primary btn-sm px-1 py-0 mx-1 mb-1">run frida script
                        </button>
                        <button className="btn btn-primary btn-sm px-1 py-0 mx-1 mb-1" onClick={() => {
                            containersStore.setActiveContainer(null)
                        }}>close
                        </button>
                    </div>
                </div>
            }
            return <div className="flex-grow-1 col-9 text-center"></div>;
        }}
        </Observer>
    }
}