import React from "react";
import {HostItem} from "./HostItem";
import storeContext from "../store";
import {RootStore} from "../stores";
import {Observer} from "mobx-react-lite";
import {Filters} from "./Filters";

export class HostList extends React.Component<any, any> {
    static contextType = storeContext
    
    componentDidMount() {
        const {hostsStore} = this.context as RootStore;
        hostsStore.loadHosts()
    }

    render() {
        return <Observer>{() => {
            const {hostsStore, settingsStore} = this.context as RootStore;
            const {loading, error, hosts} = hostsStore;
            const {stateFilter, providers, nameFilter} = settingsStore;

            let errorView = <span></span>
            if (error.length > 0) {
                errorView =
                    <div className="text-danger">
                        {error}
                    </div>
            }

            let loadingView = <span/>
            if (loading) {
                if (hosts.length > 0) {
                    loadingView = <div className="progress-sm"></div>
                } else {
                    loadingView = <div className="text-primary">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="m-2">loading hosts...</span>
                    </div>
                }
            }

            let filters = <Filters/>

            const hostsView = hosts.map(
                (item, index) =>
                    <HostItem host={item} key={item.name} filter={stateFilter} providers={providers} nameFilter={nameFilter}/>
            )

            return <div className="list-group list-group-flush position-relative mt-2" style={{width: "500px"}}>
                {filters}
                {errorView}
                {loadingView}
                {hostsView}
            </div>
        }}</Observer>
    }
}