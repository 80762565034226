import {Component} from "react";
import {Host} from "../models";
import {ContainerList} from "./ContainerList";

interface Props {
    host: Host
    filter: string
    providers: string[]
    nameFilter: string
}

export class HostItem extends Component<Props, any> {
    render() {
        let status = "warning"
        switch (this.props.host.status) {
            case "online":
                status = "success"
                break
            case "down":
                status = "danger"
                break
        }
        return <div>
            <div className="list-group-item list-group-item-action" data-bs-target={"#host-" + this.props.host.name}
                 data-bs-toggle="collapse"
                 role="button"
                 aria-expanded="false" aria-controls={"host-" + this.props.host.name}>
                <div className="me-2 ms-auto d-flex justify-content-between align-items-center">
                    <div><span className="fw-bold">{this.props.host.name}</span><small
                        className="text-muted px-1">{this.props.host.ip}</small></div>
                    <span className={"badge rounded-pill bg-" + status + " px-2 py-1"}>{this.props.host.status}</span>
                </div>
            </div>
            <div className={this.props.host.status === "online" ? "collapse show" : "collapse"}
                 id={"host-" + this.props.host.name}>
                <div className="m-1 mb-3">
                    <ContainerList host={this.props.host} filter={this.props.filter} providers={this.props.providers} nameFilter={this.props.nameFilter}/>
                </div>
            </div>
        </div>;
    }
}