import React, {Component} from "react";
import {Container, Host} from "../models";
import {ContainerItem} from "./ContainerItem";
import {Observer} from "mobx-react-lite";
import {RootStore} from "../stores";
import storeContext from "../store";

interface Props {
    host: Host
    filter: string
    providers: string[]
    nameFilter: string
}

export class ContainerList extends Component<Props, any> {
    static contextType = storeContext

    componentDidMount() {
        const {containersStore} = this.context as RootStore
        containersStore.loadContainers(this.props.host.name)
    }

    render() {
        return <Observer>{() => {
            const {containersStore} = this.context as RootStore
            const containers = containersStore.getContainers(this.props.host.name, this.props.filter, this.props.providers, this.props.nameFilter)
            const loading = containersStore.getLoading(this.props.host.name)
            const error = containersStore.getError(this.props.host.name)

            let errorView = <span></span>
            if (error.length > 0) {
                errorView =
                    <div className="text-danger">
                        {error}
                    </div>
            }

            let loadingView = <span/>
            if (loading) {
                if (containers.length > 0) {
                    loadingView = <div className="progress-sm"></div>
                } else {
                    loadingView = <div className="text-primary">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="m-2">loading container list...</span>
                    </div>
                }
            }

            const containersView = containers.map(
                (item, index) =>
                    <ContainerItem container={item} key={index} active={containersStore.isActive(item)}
                                   onConnectClick={this.onContainerConnect.bind(this)}/>
            )

            return <div className="list-group list-group-flush">
                {errorView}
                {loadingView}
                {containersView}
            </div>;
        }}
        </Observer>
    }

    private onContainerConnect(container: Container) {
        const {containersStore} = this.context as RootStore
        containersStore.setActiveContainer(container)
    }
}