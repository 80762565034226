import React, {ChangeEvent, FormEvent} from "react";
import {RootStore} from "../stores";
import {Observer} from "mobx-react-lite";
import storeContext from "../store";
import settingsStore from "../stores/SettingsStore";

export class Filters extends React.Component<any, any> {
    static contextType = storeContext

    changeFilter(e: FormEvent) {
        const {settingsStore} = this.context as RootStore;
        const filter = (e.target as HTMLInputElement).value;

        settingsStore.setStateFilter(filter)
    }

    changeProviders(e: FormEvent) {
        const providers = new FormData(e.currentTarget as HTMLFormElement).getAll("provider") as string[];
        settingsStore.setProviders(providers)
    }

    changeNameFilter(e: ChangeEvent) {
        const nameFilter = (e.target as HTMLInputElement).value
        settingsStore.setNameFilter(nameFilter)
    }

    render() {
        return <Observer>{() => {
            const {settingsStore, containersStore} = this.context as RootStore;
            const {stateFilter, providers, nameFilter} = settingsStore;

            const availableProviders = containersStore.getProviders();
            const providersElem = availableProviders.map((item, id) =>
                <div className={"form-check form-check-inline"} key={id}>
                    <label className={"form-check-label"}>
                        <input className={"form-check-input"} type={"checkbox"}
                               defaultChecked={providers.includes(item)} value={item} name={"provider"}/> {item}</label>
                </div>)

            return <div className={"small"}>
                <div className={"d-flex justify-content-between"}>
                    <strong className={"pe-3"}>State:</strong>
                    <form className={"d-flex justify-content-end"} onChange={this.changeFilter.bind(this)}>
                        <div className={"form-check form-check-inline"}>
                            <label className={"form-check-label"}>
                                <input className={"form-check-input"} type={"radio"} value={"all"} name={"filter"}
                                       id={"filter-all"} defaultChecked={stateFilter === "all"}/> All</label>
                        </div>
                        <div className={"form-check form-check-inline"}>
                            <label className={"form-check-label"}>
                                <input className={"form-check-input"} type={"radio"} value={"running"} name={"filter"}
                                       id={"filter-running"}
                                       defaultChecked={stateFilter === "running"}/> Running</label>
                        </div>
                        <div className={"form-check form-check-inline"}>
                            <label className={"form-check-label"}>
                                <input className={"form-check-input"} type={"radio"} value={"exited"} name={"filter"}
                                       id={"filter-exited"} defaultChecked={stateFilter === "exited"}/> Exited</label>
                        </div>
                    </form>
                </div>
                <div className={"d-flex justify-content-between"}>
                    <strong className={"pe-3"}>Providers:</strong>
                    <form className={"d-flex justify-content-end"} onChange={this.changeProviders.bind(this)}>
                        {providersElem}
                    </form>
                </div>
                <div className={"d-flex justify-content-between mb-1 form-group"}>
                    <strong className={"pe-3"}>Name:</strong>
                    <input type="text" defaultValue={nameFilter} onChange={this.changeNameFilter.bind(this)}/>
                </div>
            </div>
        }}</Observer>
    }
}