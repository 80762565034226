import settingsStore, {SettingsStore} from "./SettingsStore";
import hostsStore, {HostsStore} from "./HostsStore";
import containersStore, {ContainersStore} from "./ContainersStore";

export type RootStore = {
    settingsStore: SettingsStore;
    hostsStore: HostsStore;
    containersStore: ContainersStore;
}

const rootStore: RootStore = {
    settingsStore,
    hostsStore,
    containersStore
};

export default rootStore;