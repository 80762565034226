import React from "react";
import {Container} from "../models";
import {HostList} from "./HostList";
import cfg from "../config.json";
import {Emulator} from "./Emulator";

interface Props {
}

interface State {
    error: string
    items: Container[]
    currentItem?: Container
}

export class Main extends React.Component<Props, State> {
    //static contextType = storeContext

    constructor(props: Props) {
        super(props);

        this.state = {
            error: "",
            items: [],
            currentItem: undefined,
        }
    }

    componentDidMount() {
        //const {settingsStore} = this.context as RootStore;
        //console.log(settingsStore.currentTheme);
    }

    render() {
        if (this.state.error.length > 0) {
            return this.errorUI()
        }

        /* let view = <div className="flex-grow-1 col-9">&nbsp;</div>;
         if (this.state.currentItem !== undefined) {
             view = <div className="flex-grow-1 col-9">
                 <iframe src={"/ws.html?container=" + this.state.currentItem.id + "&host=" + cfg.BASE_WS_URL}
                         title="emulator"/>
             </div>
         }*/

        return <div className="container-fluid d-flex justify-content-between h-100">
            <HostList/>
            <Emulator/>
        </div>
    }

    private errorUI() {
        return <div>{this.state.error}</div>;
    }

    private onContainerConnect(item: Container) {
        this.setState({currentItem: item})
    }

    private onContainerDestroy(item: Container) {
        console.log("destroy", item)
    }
}