import React from "react";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {useStore} from "../store";

export const ThemeSwitcher = () => {
    const {switcher, themes, currentTheme, status} = useThemeSwitcher();
    const {settingsStore} = useStore();

    if (status === 'loading') {
        return <div>Loading styles...</div>;
    }

    const toggleDarkMode = () => {
        const theme = settingsStore.currentTheme === themes.dark ? themes.light : themes.dark;
        switcher({theme: theme});
        settingsStore.setCurrentTheme(theme)
    };

    const icon = currentTheme === "dark" ? <i className="bi bi-moon-fill"></i> : <i className="bi bi-moon"></i>

    return <div className="position-absolute top-0 end-0 me-2 mt-1">
        <button className="btn btn-sm btn-link text-muted" onClick={toggleDarkMode}>{icon}</button>
    </div>;
}