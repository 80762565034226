import {action, makeObservable, observable, reaction} from "mobx";

export class SettingsStore {
    currentTheme: string = "light"
    stateFilter: string = "all"
    providers: string[] = []
    nameFilter: string = ""

    constructor() {
        makeObservable(this, {
            currentTheme: observable,
            stateFilter: observable,
            providers: observable,
            nameFilter: observable,
            setCurrentTheme: action,
            setStateFilter: action,
            setProviders: action,
            setNameFilter: action,
        })

        reaction(
            () => this.currentTheme,
            theme => {
                if (theme) {
                    window.localStorage.setItem("currentTheme", theme)
                } else {
                    window.localStorage.removeItem("currentTheme")
                }
            }
        )

        reaction(
            () => this.stateFilter,
            state => {
                if (state) {
                    window.localStorage.setItem("stateFilter", state)
                } else {
                    window.localStorage.removeItem("stateFilter")
                }
            }
        )

        reaction(
            () => this.providers,
            providers => {
                if (providers) {
                    window.localStorage.setItem("providers", JSON.stringify(providers))
                } else {
                    window.localStorage.removeItem("providers")
                }
            }
        )

        reaction(
            () => this.nameFilter,
            name => {
                if (name) {
                    window.localStorage.setItem("nameFilter", name)
                } else {
                    window.localStorage.removeItem("nameFilter")
                }
            }
        )

        const ct = window.localStorage.getItem("currentTheme")
        if (ct == null) {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                this.setCurrentTheme("dark")
            } else {
                this.setCurrentTheme("light")
            }
        } else {
            this.setCurrentTheme(ct)
        }

        const sf = window.localStorage.getItem("stateFilter")
        if (sf == null) {
            this.setStateFilter("all")
        } else {
            this.setStateFilter(sf)
        }

        const providers = window.localStorage.getItem("providers")
        if (providers == null) {
            this.setProviders([])
        } else {
            this.setProviders(JSON.parse(providers))
        }

        const name = window.localStorage.getItem("nameFilter")
        if (name == null) {
            this.setNameFilter("")
        } else {
            this.setNameFilter(name)
        }
    }

    setCurrentTheme(theme: string) {
        this.currentTheme = theme
    }

    setStateFilter(state: string) {
        this.stateFilter = state
    }

    setProviders(providers: string[]) {
        this.providers = providers
    }

    setNameFilter(name: string) {
        this.nameFilter = name
    }
}

const settingsStore = new SettingsStore();
export default settingsStore;